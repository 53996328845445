const links = {
	aboutUs: [
		{
			title: 'Meet the Team',
			url: '/'
		},
		{
			title: 'Case studies & News',
			url: '/'
		},
		{
			title: 'Our Technology',
			url: '/'
		}
	],
	learn: [
		{
			title: 'How to Guide',
			url: '/'
		},
		{
			title: 'Working with Schools',
			url: '/'
		},
		{
			title: 'Get help',
			url: '/'
		}
	]
}
export default links

export const termsAndPolicyLinks = {
	terms: {
		title: 'Terms of use',
		link: 'https://schoolsmobile.com/en-us/terms-conditions-usage/'
	},
	policy: {
		title: 'Privacy policy',
		link: 'https://schoolsmobile.com/en-us/privacy-policy-statement/'
	}
}

export const PDFLink = '/pdf/ACTIVATION_INSTRUCTIONS_TEMP.pdf'

export const helpLinks = [
	{
		title: 'View the Policies',
		url: 'https://schoolsmobile.com/en-us/internet-safe-education'
	},
	{
		title: 'Contact Us',
		url: 'https://schoolsmobile.com/en-us/contact-us/'
	},
	{
		title: 'Install Instructions',
		url: 'https://schoolsmobile.com/en-us/install-internet-safe-education/'
	}
]
export const FAQLink = 'https://schoolsmobile.com/en-us/faqs-copy-2/'
export const protectLink = 'https://schoolsmobile.com/en-us/internet-safe-education'
