import image1 from 'src/assets/images/icons/prot1.svg'
import image2 from 'src/assets/images/icons/prot2.svg'
import image3 from 'src/assets/images/icons/prot3.svg'

const protectionData = [
	{
		id: 1,
		text: 'Malicious Websites, Malware & Phishing',
		image: image1
	},
	{
		id: 2,
		text: 'Adult Content',
		image: image2
	},
	{
		id: 3,
		text: 'Gambling',
		image: image3
	},
	{
		id: 4,
		text: 'Age-inappropriate Content',
		image: image2
	}
]

export default protectionData
