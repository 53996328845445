import { useMutation } from '@apollo/client'
import { useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router'
import AddLicenceFormInner from 'src/components/elements/AddLicense/AddLicenceFormInner'
import ExtraLicense from 'src/components/elements/AddLicense/ExtraLicense'
import Button from 'src/components/elements/Button'
import Header from 'src/components/modules/Header'
import { Progress } from 'src/components/modules/Header/modules'
import { useDispatchSteps } from 'src/context/steps-context'
import { ADD_EXTRA_LICENCES, ADD_LICENCES, DELETE_EXTRA_LICENCES } from 'src/graphql/mutation'
import WithCredentials from 'src/hoc/withCredentials'
import ROUTES_NAME from 'src/router/routesNames'
import { filterArrayValues } from 'src/utils'

const DashboardAddLicence = () => {
	const methods = useForm()
	const { handleSubmit, watch } = methods
	const navigate = useNavigate()
	const [mainError, setMainError] = useState(null)
	const [error, setError] = useState([])
	const { dispatch } = useDispatchSteps()

	const [deleteExtraLicences] = useMutation(DELETE_EXTRA_LICENCES)

	useEffect(() => {
		deleteExtraLicences()
		dispatch({
			type: 'DELETE_STEPS'
		})
	}, [])

	const [addLicences, { loading }] = useMutation(ADD_LICENCES, {
		onCompleted: (vals) => {
			if (vals.addLicence.ok) {
				navigate(ROUTES_NAME.DASHBOARD_CREATE_CHILD)
			} else {
				if (!vals.addLicence.licenceIsValid[0].isValid) {
					setMainError({ message: vals.addLicence.licenceIsValid[0].error })
				} else {
					setMainError(null)
				}
				const arr = vals.addLicence.licenceIsValid.slice(1)
				const err = arr.map((item) => (!item.isValid ? { message: item.error } : null))
				setError(err)
			}
		}
	})
	const [addExtraLicences, { loading: extraLoading }] = useMutation(ADD_EXTRA_LICENCES, {
		onCompleted: (vals) => {
			if (vals.addExtraLicence.ok) {
				navigate(ROUTES_NAME.DASHBOARD_PAYMENT)
				dispatch({
					type: 'SET_STEPS',
					payload: {
						steps: 4
					}
				})
			} else {
				if (!vals.addExtraLicence.licenceIsValid[0].isValid) {
					setMainError({ message: vals.addExtraLicence.licenceIsValid[0].error })
				} else {
					setMainError(null)
				}
				const arr = vals.addExtraLicence.licenceIsValid.slice(1)
				const err = arr.map((item) => (!item.isValid ? { message: item.error } : null))
				setError(err)
			}
		}
	})

	const onSubmit = handleSubmit((values) => {
		const data = filterArrayValues(values)
		const correctData = data.filter((item) => item).length === 0 ? [] : data
		if (values.counter > 0) {
			addExtraLicences({ variables: { extraLicenceQty: values.counter, codes: correctData } })
		} else if (data.length) {
			addLicences({ variables: { codes: data } })
		}
	})
	const onCancel = () => {
		navigate(ROUTES_NAME.DASHBOARD)
	}

	const allValues = watch()
	const isDisabledSubmit = useMemo(() => {
		const codes = filterArrayValues(allValues).filter((item) => item)
		if (Object.keys(allValues).length === 0) return true
		return allValues.counter === 0 && codes.length === 0
	}, [allValues])

	const isLoading = extraLoading || loading
	return (
		<>
			<Header type="profile" />
			<div className="dashboard-step">
				<div className="container container-md-center container-lg-center">
					<Progress step={1} className="dashboard-step__progress" position="top" />
					<div className="dashboard-step__titles">
						<h3 className="dashboard-step__title">Set up in under 90 seconds!</h3>
						<p className="dashboard-step__subtitle">
							Add a licence code if you have one from your school or you can subscribe
							for just $7 per month - subscribe today and enjoy 14 days free of
							charge.
						</p>
					</div>
					<FormProvider {...methods}>
						<form onSubmit={onSubmit} className="dashboard-step__licenceForm">
							<AddLicenceFormInner
								mainError={mainError}
								error={error}
								setError={setError}
								required={false}
								dashboard
							/>
							<ExtraLicense dashboard />
							<div className="step-buttons">
								<div className="step-buttons__wrapper">
									<Button color="empty" onClick={onCancel}>
										Cancel
									</Button>
									<Button
										type="submit"
										color="primary"
										isLoading={isLoading}
										isDisable={isDisabledSubmit}
										classname="step-buttons__next"
									>
										Continue
									</Button>
								</div>
							</div>
						</form>
					</FormProvider>
				</div>
			</div>
		</>
	)
}

export default WithCredentials(DashboardAddLicence)
