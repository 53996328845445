import BlockTitle from 'src/components/elements/BlockTitle'
import styles from './HeroBlock.module.scss'

const HeroBlock = () => {
	return (
		<div className={styles.hero}>
			<div className={styles.hero__school}>
				<img src="/images/ISE-logo.png" alt="" />
				<span>Internet Safe Education</span>
			</div>
			<div className={styles.hero__container}>
				<BlockTitle color="white" classname={styles.hero__title}>
					Balanced, Safe & Engaged Online Usage... Made Simple
				</BlockTitle>
				<p className={styles.hero__subtitle}>
					The easy tool to help parents look after their children when using technology
					and devices.
				</p>
			</div>
		</div>
	)
}

export default HeroBlock
