import AddLicense from 'src/components/elements/AddLicense'
import BlockTitle from 'src/components/elements/BlockTitle'

import styles from './GetStarted.module.scss'

const GetStarted = () => {
	const text =
		'Add a licence code if you have one from your school or you can subscribe for just $7 per month - subscribe today and enjoy 14 days free of charge.'

	return (
		<div className={styles.block}>
			<div className="container">
				<div className={styles.desktop}>
					<div className={styles.desktop__titles}>
						<BlockTitle color="black">Set up in under 90 seconds!</BlockTitle>
						<p className={styles.block__subtitle}>{text}</p>
					</div>
					<AddLicense />
				</div>
			</div>
		</div>
	)
}

export default GetStarted
