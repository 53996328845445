import { useMutation, useQuery } from '@apollo/client'
import { useNavigate } from 'react-router'
import Button from 'src/components/elements/Button'
import Loader from 'src/components/elements/Loader'
import Header from 'src/components/modules/Header'
import { CREATE_PAYMENT } from 'src/graphql/mutation'
import { GET_ANONYMOUS_USER } from 'src/graphql/query'
import ROUTES_NAME from 'src/router/routesNames'
import { useGlobalBadgeContext } from 'src/context/global-badge'

export const PaymentBlock = ({ licenses }) => {
	const licenceType = {
		PRE_PAID: 'Pre-paid',
		EXTRA: 'Extra'
	}

	return (
		<div className="payment-step__block">
			<div className="payment-step__top">
				<div className="payment-step__row">
					<div className="payment-step__licence payment-step__lineheader">licence</div>
					<div className="payment-step__child payment-step__lineheader">added</div>
					<div className="payment-step__child payment-step__lineheader">price</div>
				</div>
				{licenses?.licences?.map((item) => (
					<div className="payment-step__row" key={item.licenceType}>
						<div className="payment-step__licence">{licenceType[item.licenceType]}</div>
						<div className="payment-step__child">{item.quantity} child</div>
						<div className="payment-step__child">
							{item.price ? `$${item.price}` : 'Paid'}
						</div>
					</div>
				))}
			</div>
			<div className="payment-step__bottom">
				<div className="payment-step__total">Total amount </div>
				<div className="payment-step__total">${licenses?.totalAmount}/mo</div>
			</div>
		</div>
	)
}
const PaymentStep = () => {
	const navigate = useNavigate()
	const { showModal, renderComponent } = useGlobalBadgeContext()
	const { data, loading } = useQuery(GET_ANONYMOUS_USER, {
		fetchPolicy: 'network-only'
	})
	const [createPayment, { loading: paymentLoading }] = useMutation(CREATE_PAYMENT, {
		onCompleted: (res) => {
			if (res.createPayment.ok) {
				window.location.href = res.createPayment.paymentUrl
			} else {
				showModal('error', {
					color: 'error',
					text: 'Something went wrong.',
					time: 15000
				})
			}
		}
	})
	const onProceed = () => {
		createPayment()
	}
	const onCancel = () => {
		navigate(ROUTES_NAME.HOME)
	}
	return (
		<div className="step payment-step">
			<Header type="progress" step={1} />
			<div className="container container-md-center container-lg-center ">
				<div className="payment-step__wrapper">
					{renderComponent()}
					{loading ? (
						<Loader center width="80px" />
					) : (
						<>
							<h2 className="payment-step__title">Everything looks good?</h2>
							<p className="payment-step__subtitle">
								Please proceed to payment to activate your licence.
							</p>
							<PaymentBlock licenses={data?.anonymousUser?.licences} />
							<div className="payment-step__disclaimer">
								<i className="icon-info-circle" />
								<p>
									You will automatically be charged monthly. You can unsubscribe
									at any time.
								</p>
							</div>
						</>
					)}
				</div>
			</div>
			<div className="step-buttons">
				<div className="step-buttons__wrapper">
					<Button color="empty" onClick={onCancel}>
						Cancel
					</Button>
					<Button
						type="submit"
						color="primary"
						classname="step-buttons__next"
						onClick={onProceed}
						isLoading={paymentLoading}
					>
						Proceed to payment
					</Button>
				</div>
			</div>
		</div>
	)
}

export default PaymentStep
